import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    ssr: true,
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
    },
    theme: {
      defaultTheme: 'default',
      themes: {
        default: {
          dark: false,
          colors: {
            'background': '#FFFFFF',
            'surface': '#FFFFFF',
            'surface-variant': '#424242',
            'on-surface-variant': '#EEEEEE',
            'primary': '#0072bb',
            'primary-darken-1': '#3700B3',
            'secondary': '#03DAC6',
            'secondary-darken-1': '#018786',
            'error': '#B00020',
            'info': '#2196F3',
            'success': '#4CAF50',
            'warning': '#FB8C00',
          },
        },
      },
    },
  })
  app.vueApp.use(vuetify)
})
