import { default as our_45storym10clHB6NBMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/our-story.vue?macro=true";
import { default as plan_45your_45visitd37w0zY3JpMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/plan-your-visit.vue?macro=true";
import { default as what_45we_45believeI4D0QfnU6nMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/what-we-believe.vue?macro=true";
import { default as contactfjCedkOW7bMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/contact.vue?macro=true";
import { default as what_45is_45the_45gospel3hC2WeXNHwMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/faith/what-is-the-gospel.vue?macro=true";
import { default as forgot_45passwordL858Wvy0SaMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/forgot-password.vue?macro=true";
import { default as giveJLK8D7SXpqMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/give.vue?macro=true";
import { default as indexlFv83rotiWMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/index.vue?macro=true";
import { default as loginmFKDUbWLi0Meta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/login.vue?macro=true";
import { default as indexsBxfBSLONNMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/calendar/index.vue?macro=true";
import { default as indexWfXIuTG9FZMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/index.vue?macro=true";
import { default as my_45accountqY7epXKXATMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/my-account.vue?macro=true";
import { default as _91user_id_93Yem19cEETPMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users/[user_id].vue?macro=true";
import { default as indexGkQPRLmyrSMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users/index.vue?macro=true";
import { default as usershqozQA3TPAMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users.vue?macro=true";
import { default as childrengKfJA1q4zeMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/children.vue?macro=true";
import { default as day_45campUxs5xzyhTMMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/day-camp.vue?macro=true";
import { default as discipleshipkoSEzVscgRMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/discipleship.vue?macro=true";
import { default as youthalwRFui4bqMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/youth.vue?macro=true";
import { default as privacyPCojaRxANUMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/privacy.vue?macro=true";
export default [
  {
    name: our_45storym10clHB6NBMeta?.name ?? "about-our-story",
    path: our_45storym10clHB6NBMeta?.path ?? "/about/our-story",
    meta: our_45storym10clHB6NBMeta || {},
    alias: our_45storym10clHB6NBMeta?.alias || [],
    redirect: our_45storym10clHB6NBMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/our-story.vue").then(m => m.default || m)
  },
  {
    name: plan_45your_45visitd37w0zY3JpMeta?.name ?? "about-plan-your-visit",
    path: plan_45your_45visitd37w0zY3JpMeta?.path ?? "/about/plan-your-visit",
    meta: plan_45your_45visitd37w0zY3JpMeta || {},
    alias: plan_45your_45visitd37w0zY3JpMeta?.alias || [],
    redirect: plan_45your_45visitd37w0zY3JpMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/plan-your-visit.vue").then(m => m.default || m)
  },
  {
    name: what_45we_45believeI4D0QfnU6nMeta?.name ?? "about-what-we-believe",
    path: what_45we_45believeI4D0QfnU6nMeta?.path ?? "/about/what-we-believe",
    meta: what_45we_45believeI4D0QfnU6nMeta || {},
    alias: what_45we_45believeI4D0QfnU6nMeta?.alias || [],
    redirect: what_45we_45believeI4D0QfnU6nMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/what-we-believe.vue").then(m => m.default || m)
  },
  {
    name: contactfjCedkOW7bMeta?.name ?? "contact",
    path: contactfjCedkOW7bMeta?.path ?? "/contact",
    meta: contactfjCedkOW7bMeta || {},
    alias: contactfjCedkOW7bMeta?.alias || [],
    redirect: contactfjCedkOW7bMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: what_45is_45the_45gospel3hC2WeXNHwMeta?.name ?? "faith-what-is-the-gospel",
    path: what_45is_45the_45gospel3hC2WeXNHwMeta?.path ?? "/faith/what-is-the-gospel",
    meta: what_45is_45the_45gospel3hC2WeXNHwMeta || {},
    alias: what_45is_45the_45gospel3hC2WeXNHwMeta?.alias || [],
    redirect: what_45is_45the_45gospel3hC2WeXNHwMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/faith/what-is-the-gospel.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordL858Wvy0SaMeta?.name ?? "forgot-password",
    path: forgot_45passwordL858Wvy0SaMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordL858Wvy0SaMeta || {},
    alias: forgot_45passwordL858Wvy0SaMeta?.alias || [],
    redirect: forgot_45passwordL858Wvy0SaMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: giveJLK8D7SXpqMeta?.name ?? "give",
    path: giveJLK8D7SXpqMeta?.path ?? "/give",
    meta: giveJLK8D7SXpqMeta || {},
    alias: giveJLK8D7SXpqMeta?.alias || [],
    redirect: giveJLK8D7SXpqMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/give.vue").then(m => m.default || m)
  },
  {
    name: indexlFv83rotiWMeta?.name ?? "index",
    path: indexlFv83rotiWMeta?.path ?? "/",
    meta: indexlFv83rotiWMeta || {},
    alias: indexlFv83rotiWMeta?.alias || [],
    redirect: indexlFv83rotiWMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginmFKDUbWLi0Meta?.name ?? "login",
    path: loginmFKDUbWLi0Meta?.path ?? "/login",
    meta: loginmFKDUbWLi0Meta || {},
    alias: loginmFKDUbWLi0Meta?.alias || [],
    redirect: loginmFKDUbWLi0Meta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexsBxfBSLONNMeta?.name ?? "members-calendar",
    path: indexsBxfBSLONNMeta?.path ?? "/members/calendar",
    meta: indexsBxfBSLONNMeta || {},
    alias: indexsBxfBSLONNMeta?.alias || [],
    redirect: indexsBxfBSLONNMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: indexWfXIuTG9FZMeta?.name ?? "members",
    path: indexWfXIuTG9FZMeta?.path ?? "/members",
    meta: indexWfXIuTG9FZMeta || {},
    alias: indexWfXIuTG9FZMeta?.alias || [],
    redirect: indexWfXIuTG9FZMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/index.vue").then(m => m.default || m)
  },
  {
    name: my_45accountqY7epXKXATMeta?.name ?? "members-my-account",
    path: my_45accountqY7epXKXATMeta?.path ?? "/members/my-account",
    meta: my_45accountqY7epXKXATMeta || {},
    alias: my_45accountqY7epXKXATMeta?.alias || [],
    redirect: my_45accountqY7epXKXATMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/my-account.vue").then(m => m.default || m)
  },
  {
    name: usershqozQA3TPAMeta?.name ?? undefined,
    path: usershqozQA3TPAMeta?.path ?? "/members/users",
    meta: usershqozQA3TPAMeta || {},
    alias: usershqozQA3TPAMeta?.alias || [],
    redirect: usershqozQA3TPAMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users.vue").then(m => m.default || m),
    children: [
  {
    name: _91user_id_93Yem19cEETPMeta?.name ?? "members-users-user_id",
    path: _91user_id_93Yem19cEETPMeta?.path ?? ":user_id()",
    meta: _91user_id_93Yem19cEETPMeta || {},
    alias: _91user_id_93Yem19cEETPMeta?.alias || [],
    redirect: _91user_id_93Yem19cEETPMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: indexGkQPRLmyrSMeta?.name ?? "members-users",
    path: indexGkQPRLmyrSMeta?.path ?? "",
    meta: indexGkQPRLmyrSMeta || {},
    alias: indexGkQPRLmyrSMeta?.alias || [],
    redirect: indexGkQPRLmyrSMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: childrengKfJA1q4zeMeta?.name ?? "ministries-children",
    path: childrengKfJA1q4zeMeta?.path ?? "/ministries/children",
    meta: childrengKfJA1q4zeMeta || {},
    alias: childrengKfJA1q4zeMeta?.alias || [],
    redirect: childrengKfJA1q4zeMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/children.vue").then(m => m.default || m)
  },
  {
    name: day_45campUxs5xzyhTMMeta?.name ?? "ministries-day-camp",
    path: day_45campUxs5xzyhTMMeta?.path ?? "/ministries/day-camp",
    meta: day_45campUxs5xzyhTMMeta || {},
    alias: day_45campUxs5xzyhTMMeta?.alias || [],
    redirect: day_45campUxs5xzyhTMMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/day-camp.vue").then(m => m.default || m)
  },
  {
    name: discipleshipkoSEzVscgRMeta?.name ?? "ministries-discipleship",
    path: discipleshipkoSEzVscgRMeta?.path ?? "/ministries/discipleship",
    meta: discipleshipkoSEzVscgRMeta || {},
    alias: discipleshipkoSEzVscgRMeta?.alias || [],
    redirect: discipleshipkoSEzVscgRMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/discipleship.vue").then(m => m.default || m)
  },
  {
    name: youthalwRFui4bqMeta?.name ?? "ministries-youth",
    path: youthalwRFui4bqMeta?.path ?? "/ministries/youth",
    meta: youthalwRFui4bqMeta || {},
    alias: youthalwRFui4bqMeta?.alias || [],
    redirect: youthalwRFui4bqMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/youth.vue").then(m => m.default || m)
  },
  {
    name: privacyPCojaRxANUMeta?.name ?? "privacy",
    path: privacyPCojaRxANUMeta?.path ?? "/privacy",
    meta: privacyPCojaRxANUMeta || {},
    alias: privacyPCojaRxANUMeta?.alias || [],
    redirect: privacyPCojaRxANUMeta?.redirect,
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/privacy.vue").then(m => m.default || m)
  }
]